import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BlogCard } from './Components'
import { Button, Input, Modal } from 'antd'
import img_prev from "./image.png"
import moment from 'moment'
import { toast } from 'react-toastify'
import axios from 'axios'

function SingleBlog() {

  const { id } = useParams()
  const navigate = useNavigate()

  const [image, setImage] = useState(null)
  const [temp_, setTemp_] = useState(false)
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [more, setMore] = useState(false)
  const [put, setPut] = useState(false)
  const [data, setData] = useState({
    updated_date: moment(Date()).format("DD-MM-YY A hh:mm"),
    title: "start writing",
    description: "",
    author: "",
    t_color: "",
    d_color: "",
    h_color: "#179bee",
    h_text_color: "",
    radius: ""
  })

  const handleChange = (e) => {
    const { name, value } = e.target

    setData((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  var public_ = false

  const inData = [
    {
      name: "title",
      placeholder: "Enter blog title",
    },
    {
      name: "description",
      placeholder: "Enter blog Description",
    },
    {
      name: "author",
      placeholder: "Enter Your name",
    },
    {
      name: "radius",
      placeholder: "eg:15, 20, 25",
      label: "Select edge radius",
      type: "number"
    },
  ]

  const colorData = [
    {
      name: "h_color",
      placeholder: "Select header color",
      label: "Select header color"
    },
    {
      name: "h_text_color",
      placeholder: "select Header text color",
      label: "Select header text color"
    },
    {
      name: "t_color",
      placeholder: "title color",
      label: "Select title text color"
    },
    {
      name: "d_color",
      placeholder: "Select description text color",
      label: "Select description text color",
    },
  ]

  const handleReset = (fieldName) => {
    setData(prevData => ({
      ...prevData,
      [fieldName]: ''
    }));
  };

  useEffect(() => {
    if (id !== "new-blog") {
      getIdData()
    }
  }, [])

  function getIdData() {
    axios.get("/blogs/" + id + "?q=blog-admin")
      .then(res => {
        setData(res.data)
        setImage(res.data?.media?.secure_url)
        setTemp_(res.data?.temp_)
      }).catch(err => {
        toast.warning(err?.response.data?.message || err.message)
      })
  }

  const handleSubmit = () => {

    if (!image) return toast.info("Provide media for the blog")

    const { description, title, author, radius, t_color, d_color, h_color, h_text_color } = data
    const formData = new FormData()
    if (image) {
      formData.append("media", image)
    }
    formData.append("description", description);
    formData.append("title", title);
    formData.append("publish", public_);
    formData.append("author", author);
    formData.append("temp_", temp_);
    formData.append("radius", radius);
    formData.append("t_color", t_color);
    formData.append("d_color", d_color);
    formData.append("h_color", h_color);
    formData.append("h_text_color", h_text_color);

    setPut(true)
    if (id !== "new-blog") {
      axios.put("/blogs/" + id, formData)
        .then(res => {
          toast.success("Blog added Sucessfully")
          navigate("/blogs")
          setPut(false)
        }).catch(err => {
          toast.warning(err?.message)
          setPut(false)
        })

    } else {
      axios.post("/blogs", formData)
        .then(res => {
          window.location.replace("/blog/" + res.data.data?._id)
          toast.success("Blog added Sucessfully")
          setPut(false)
        }).catch(err => {
          toast.info(err.response?.data.message || err.message)
          setPut(false)
        })
    }
  }

  function handleDelete () {
    setPut(true)
    axios.delete("/blogs/" + id)
    .then(res => {
      toast.success("Blog Deleted Sucessfully")
      setPut(false)
      navigate("/blogs")
    }).catch(err => {
      toast.info(err.response?.data.message || err.message)
      setPut(false)
    })
  }

  return (
    <div className='blog-container'>
      {put && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}
      <BlogCard
        img={typeof image === "string" ? image : image && URL.createObjectURL(image)}
        date={data.updated_date}
        author={data.author}
        title={data.title}
        description={data.description}
        h_color={data.h_color}
        h_text_color={data.h_text_color}
        t_color={data.t_color}
        d_color={data.d_color}
        radius={data.radius}
        temp_={temp_}
      />
      <div style={{ padding: 20, maxWidth: 500 }}>
        <div style={{ position: "relative" }}>
          <label htmlFor='img' style={{ marginBottom: 10, padding: 10, borderColor: "gray", maxWidth: 430, borderWidth: 2, borderStyle: "dashed", borderRadius: 15, width: "80vw", overflow: "hidden" }}>
            <div style={{ display: "flex", justifyContent: "start", gap: 4, alignItems: "center" }}>
              <svg
                style={{ width: 80 }}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
              <p className='truncate'>{image?.name}</p>
            </div>
            <span style={{ color: "#41C5F2", display: "flex", justifyContent: "center" }}>PNG, JPG, GIF up to 5MB</span>
          </label>
          <input type="file" id='img' accept='image/*' hidden onChange={(e) => setImage(e.target.files[0])} />
          {image && <div role='button' style={{ color: "red", textAlign: "end" }} onClick={() => setImage(null)}>Remove Image</div>}
        </div>
        {inData.map(dat =>
          <div style={{ marginTop: 16 }}>
            <label htmlFor={dat.name} style={{ marginBottom: 10, textTransform: "capitalize" }}>{data.label || data.name}</label>
            <Input title={dat.name}
              type={dat.type || "text"}
              placeholder={dat.placeholder}
              name={dat.name} id={dat.name}
              onChange={handleChange}
              size='large'
              value={data[dat?.name]}
              autocomplete="off"
              defaultValue={data[dat?.name]}
            />
          </div>

        )}
        <div style={{ display: "flex", alignItems: "center", gap: 10, marginTop: 10 }}>
          <label htmlFor="temp_" >Rounded template</label>
          <input
            type="checkbox"
            name="temp_" id="temp_"
            style={{ width: 15, height: 15 }}
            value={temp_}
            onChange={(e) => setTemp_(e.target.checked)}
          />
        </div>
        <div role='button' style={{ color: "green", textAlign: "end",marginBottom:20 }} onClick={() => setMore(!more)}>{more ? "Hide" : "More +"}</div>

        {more && <div style={{ marginTop: 16, marginBottom:20 }}>
          {
            colorData.map(dat =>
              <div style={{ marginTop: 16 }}>
                <label htmlFor={dat.name} style={{ marginBottom: 10, textTransform: "capitalize" }}>{dat.label}</label>
                <Input title={dat.name}
                  type={"color"}
                  placeholder={dat.placeholder}
                  name={dat.name}
                  id={dat.name}
                  onChange={handleChange}
                  size='large'
                  defaultValue={data[dat?.name]}
                  autocomplete="off"
                />
                <div role='button' style={{ color: "red", textAlign: "end" }}
                  onClick={() => handleReset(dat.name)}>reset</div>
              </div>
            )}
        </div>}
        <div style={{display:"flex",justifyContent:"space-between", gap:10}}>
        <Button onClick={handleSubmit}>Save</Button>
        <Button onClick={() => setOpen(true)}>Publish</Button>
        {id !==  "new-blog" && <Button onClick={()=>setOpen2(true)}  type="primary" danger>Delete</Button>}
        <Button onClick={() => navigate(-1)} type="primary" danger>Cancel</Button>
</div>
      </div>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => { public_ = true; handleSubmit(); setOpen(false) }}
      >
        <h2 style={{ color: "blueviolet" }}>Read the Message</h2>
        Publish Method will make this post available to public press ok to continue or press cancel

      </Modal>
      <Modal
        open={open2}
        onCancel={() => setOpen2(false)}
        onOk={() => {handleDelete(); setOpen2(false) }}
      >
        <h2 style={{ color: "#f11" }}>Read the Message</h2>
        Delete Method can not be undo please confirm carefully press ok to continue or press cancel

      </Modal>

    </div>
  )
}

export default SingleBlog
