import React, { useContext, useEffect, useState } from 'react'
import Card from "react-bootstrap/Card"
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Select from 'react-select';
import Spiner from "../../components/Spiner/Spiner"
import {registerfunc} from "../../services/Apis"
import { ToastContainer, toast } from "react-toastify"
import {useNavigate} from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import "./register.css"
import { addData } from '../../components/context/ContextProvider';
import Cookies from 'js-cookie';

const Register = () => {

  const [inputdata, setInputData] = useState({
    fname: "",
    lname: "",
    email: "",
    mobile: "",
    options: "",
    message: ""
  });

  const [status, setStatus] = useState("No");
  const [showspin, setShowSpin] = useState(true);

  const navigate = useNavigate();

  const { useradd, setUseradd } = useContext(addData);

  // status optios
  const saveOptions = [
    { value: 'No', label: 'No' },
    { value: 'Yes', label: 'Yes' },
  ];

  // setInput Value
  const setInputValue = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputdata, [name]: value })
  }

  // status set
  const setStatusValue = (e) => {
    setStatus(e.value)
  }

  //submit userdata
  const submitUserData = async(e) => {
    e.preventDefault();

    const { fname, lname, email, mobile, options, message } = inputdata;

    if (fname === "") {
      toast.error("First name is Required !")
    }  else if (email === "") {
      toast.error("Email is Required !")
    } else if (mobile === "") {
      toast.error("Mobile is Required !")
    } else if (mobile.length > 14) {
      toast.error("Enter Valid Mobile")
    } else if (options === "") {
      toast.error("options is Required !")
    } else if (status === "") {
      toast.error("Status is Required !")
    }  else if (message === "") {
      toast.error("message is Required !")
    } else {
      // console.log(image);

      const data = new FormData();
      data.append("fname",fname)
      data.append("email",email)
      data.append("mobile",mobile)
      data.append("options",options)
      data.append("status",status)
      // data.append("user_profile",image)
      data.append("message",message)
      if(lname !== ""){
      data.append("lname",lname)
      }

      const config = {
        "Content-Type":"multipart/form-data"
      }

      const response = await registerfunc(data,config);
      
      if(response.status === 200){
        setInputData({
          ...inputdata,
          fname:"",
          lname: "",
          email: "",
          mobile: "",
          options: "",
          message: ""
        });
        setStatus("");
        setUseradd(response.data)
        navigate("/admin");
      }else if(response.status === 400) {
        
        Cookies.remove("token")
        localStorage.removeItem("login")
        navigate("/login")
        window.location.reload()

      }
      else{
        toast.error(response?.response?.data || "Error to register")
        console.log("register err",response)
      }

    }

  }

  useEffect(() => {
    // if (image) {
    //   setPreview(URL.createObjectURL(image))
    // }

    setTimeout(() => {
      setShowSpin(false)
    }, 1200)
  }, [])


  return (
    <>
      {
        showspin ? <Spiner /> : <div className="container">
          <h2 className='text-center mt-1'>Register Your Details</h2>
          <Card className='shadow mt-3 p-3'>
            {/* <div className="profile_div text-center">
              <img src={preview ? preview : "/man.png"} alt="img" />
            </div> */}

            <Form>
              <Row>
                <Form.Group className="mb-3 col-lg-6" controlId="formBasicEmail">
                  <Form.Label>First name</Form.Label>
                  <Form.Control type="text" name='fname' value={inputdata.fname} onChange={setInputValue} placeholder='Enter FirstName' />
                </Form.Group>
                <Form.Group className="mb-3 col-lg-6" controlId="formBasicEmail">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="text" name='lname' value={inputdata.lname} onChange={setInputValue} placeholder='Enter LastName' />
                </Form.Group>
                <Form.Group className="mb-3 col-lg-6" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" name='email' value={inputdata.email} onChange={setInputValue} placeholder='Enter Email' />
                </Form.Group>
                <Form.Group className="mb-3 col-lg-6" controlId="formBasicEmail">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control type="text" name='mobile' value={inputdata.mobile} onChange={setInputValue} placeholder='Enter Mobile' />
                </Form.Group>
                <Form.Group className="mb-3 col-lg-6" controlId="formBasicEmail">
                  <Form.Label>Select Your options</Form.Label>
                  <div className='d-flex gap-4'>
                  <Form.Check
                    type={"radio"}
                    label={`Fresh Painting`}
                    name="options"
                    value={"Fresh Painting"}
                    onChange={setInputValue}
                  />
                  <Form.Check
                    type={"radio"}
                    label={`Re-Painting`}
                    name="options"
                    value={"Re-Painting"}
                    onChange={setInputValue}
                  />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3 col-lg-6" controlId="formBasicEmail">
                  <Form.Label>Save this form</Form.Label>
                  <Select options={saveOptions} onChange={setStatusValue} />
                </Form.Group>
                {/* <Form.Group className="mb-3 col-lg-6" controlId="formBasicEmail">
                  <Form.Label>Select Your Profile</Form.Label>
                  <Form.Control type="file" name='user_profile' onChange={setProfile} placeholder='Select Your Profile' />
                </Form.Group> */}
                <Form.Group className="mb-3 col-lg-6" controlId="formBasicEmail">
                  <Form.Label>Enter message</Form.Label>
                  <Form.Control type="text" name='message' value={inputdata.message} onChange={setInputValue} placeholder='Enter Your message' />
                </Form.Group>
                <Button variant="primary" type="submit" onClick={submitUserData}>
                  Submit
                </Button>
              </Row>

            </Form>
          </Card>
          <ToastContainer position="top-center" />
        </div>
      }

    </>
  )
}

export default Register