import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom"
import ContextProvider from './components/context/ContextProvider';
import axios from 'axios';
import Cookies from 'js-cookie';


axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
// axios.defaults.baseURL = "http://192.168.192.86:5000"
axios.defaults.headers = {
    Authorization: "bearrer " + Cookies.get("token")
  }


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ContextProvider>
  </React.StrictMode>
);

