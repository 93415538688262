import React, { Component, useEffect, useState } from 'react';
import './login.css';
import axios from "axios";
import { Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { BASE_URL } from '../services/helper';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';



function Login() {

  const [ user , setUser ] = useState("");
  const [ password , setPassword ] = useState("");
  const [ newPassword , setNewPassword ] = useState("");
  const [ active , setActive ] = useState(true);
  const { logoutParams } = useParams();

  const navigate = useNavigate()


  function handleSubmit () {
    
    if(user === ""){
      return message.info("User field can not be empty")
    }else if ( password === ""){
      return message.info("Password field con not be empty")
    } else {
      axios.post(BASE_URL+"/auth/login", {
        user_id:user,
        password 
      })
      .then((res)=>{
        message.info(res.data.message)
        Cookies.set("token", res.data.token)
        localStorage.setItem("login", true)
        window.location.replace("/")
      }).catch(err =>{
        console.log(err)
        message.warning(err.response?.data.message || err.message)
      })
    }

  }

  if(logoutParams){
    localStorage.removeItem("login");
    Cookies.remove("token")
  }

  
  function changePassword () {
    
    if(user === ""){
      return message.info("User field can not be empty")
    }else if(password === ""){
      return message.info("Old password is a required field!")
    }else if ( newPassword === ""){
      return message.info("New Password con not be empty")
    }
    else if ( newPassword.length < 6){
      return message.info("Password length should be gretter than 6 char")
    } 
     else {
      
      axios.put(BASE_URL+"/auth/reset-password", {
        user_id : user,
        password,
        newPassword
      }, {
        headers : {
          Authorization : "bearer "
        }
      })
      .then((res)=>{
        let login = localStorage.getItem("login")
        if(!login){
          window.location.reload()
          }
        message.info(res.data.message)
        Cookies.set("token", res.data.token)
        localStorage.setItem("login", true)
        navigate("/admin")
      }).catch(err =>{
        console.log(err)
        message.warning(err.response?.data.message || err.message)
      })
    }

  }

  return (
    <div className='l-container'>
    <div className='login-container'>
      { active ?
       <div className='fields'>
        <h1 style={{margin : "auto", color:"orangered"}}>Admin Login</h1>
      <Input size="large" onChange={(e)=>setUser(e.target.value)} placeholder="Enter User Id" prefix={<UserOutlined />} />
      <Input size="large" onChange={(e)=>setPassword(e.target.value)} placeholder="Enter password" prefix={<LockOutlined />} />

      <Button onClick={handleSubmit} type="primary" danger>
        Login
      </Button>
      <Button onClick={()=>setActive(!active)} danger>
        Change Password
      </Button>
      </div>
      :  <div className='fields'>
      <h3 style={{margin : "auto", color:"orangered",whiteSpace:"nowrap"}}>Password Reset</h3>
    <Input size="large" onChange={(e)=>setUser(e.target.value)} placeholder="Enter User Id" prefix={<UserOutlined />} />
    <Input size="large" onChange={(e)=>setPassword(e.target.value)} placeholder="Enter Old password" prefix={<LockOutlined />} />
    <Input size="large" onChange={(e)=>setNewPassword(e.target.value)} placeholder="Enter New password" prefix={<LockOutlined />} />

    <Button onClick={changePassword} type="primary" danger>
     Change Password
    </Button>
    <Button onClick={()=>setActive(!active)} danger>
    Login 
    </Button>
    </div> }
    </div>
    </div>
  )
}
export default Login;