import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./components/Headers/Headers";
import Home from "./pages/Home/Home";
import Register from "./pages/Register/Register";
import Edit from "./pages/Edit/Edit";
import Profile from "./pages/Profile/Profile";
import { Routes, Route, Navigate, Link } from "react-router-dom"
import Login from './pages/Login';
import Cookies from 'js-cookie';
import Blogs from './pages/Blogs';
import SingleBlog from './pages/Blogs/SingleBlog';
import { Button } from 'antd';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className='app'>
      <Header />
<ToastContainer/>

      <Routes>
        <Route path='/login' element={<Login />} />
        {Cookies.get("token") ? (
          <>
            <Route path='/' element={<Home />} />
            <Route path='/dashboard' element={<Home />} />
            <Route path='/add-form' element={<Register />} />
            <Route path='/edit/:id' element={<Edit />} />
            <Route path='/blogs' element={<Blogs />} />
            <Route path='/blog/:id' element={<SingleBlog />} />
            <Route path='/form-detail/:id' element={<Profile />} />
            <Route path='/*' element={<Page404 />} />

          </>
        ) : (
          <>
            <Route path='/*' element={<Login />} />
          </>
        )} 
      </Routes>
    </div>
  );
}

export default App;



export function Page404() {
  return(
    <div style={{height:"70vh", display:"flex", flexDirection:"column", gap:10, justifyContent:"center", alignItems:"center"}}>
      <h2>404</h2>
      <h2>Page not found</h2>
      <Link to="/"><Button>Home</Button></Link>
      <Link to="/blogs"><Button>Blogs</Button></Link>
    </div>
  )
}