import Cookies from 'js-cookie';
import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, useNavigate } from "react-router-dom"
import logo from "./logo.png"
import { Button, Modal } from 'antd';
import { useState } from 'react';

const Headers = () => {

  const [ open , setOpen ] = useState(false)

  const navigate = useNavigate()

  const logOut = () => {

    let conf = window.confirm("Are You Sure to Logout")
    if(conf){
    localStorage.removeItem("login")
    Cookies.remove("token")
    window.location.reload();
    }
  }
  return (
    <>
      <Navbar bg="dark" variant="dark" style={{ height: "50px" }}>
        <Container>
          <NavLink to="/" className="text-decoration-none text-light mx-2">
            <img src={logo} alt="logo" className='logo' />Admin Panel</NavLink>
          <Nav className="ml-auto">
            
            {/* {localStorage.getItem("login")? */}
            <Button onClick={()=>setOpen(true)}>More</Button>
           
            {/* : <></> } */}
          </Nav>
        </Container>
        <Modal 
        open={open}
        onCancel={()=>setOpen(false)}
        onOk={()=>setOpen(false)}
        footer={false}
        >
          <div style={{display:"flex", justifyContent:"center", marginTop:20}}>
          <div style={{display:"flex",flexDirection:"column", gap:"1rem",alignItems:"center"}}>
            <NavLink to={window.location.pathname === "/" ? null:(-1) || "/admin"} className="text-decoration-none">
              <Button style={{paddingLeft:19,paddingRight:19}}>Go Back</Button>
              </NavLink>
              <Button onClick={()=>navigate("/blogs")} style={{cursor:"pointer", paddingLeft:27, paddingRight:27}}>Blogs<span style={{
              position:"absolute", 
              paddingLeft: 4,
              paddingRight: 4,
              color:"#fff",
              background:"blue",
              borderRadius:7,
              fontSize:10,
              fontWeight:500,
              top:-5,
              right:-10
            }}>new</span></Button>

            <Button href='/blog/new-blog'>New Blog</Button>

            <Button type="primary" danger onClick={logOut} style={{cursor:"pointer",paddingLeft:22,paddingRight:22}}>Logout</Button>
           
            </div>

          </div>
        </Modal>
      </Navbar>
    </>
  )
}

export default Headers