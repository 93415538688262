import React, { useState } from 'react'
import "./index.css"
import { Switch } from 'antd'

export function BlogCard({ img, title, description, temp_, date, radius,author,onClick , t_color, d_color, h_color, h_text_color, publish, p_loading,handlePublish, _id}) {


    return (
        <div>
        {typeof publish === "boolean" && <div style={{display:"flex", justifyContent:"end",alignItems:"center", marginBottom:4, marginRight:5, gap:5}}> Publish status: <Switch checked={publish} size="default" onClick={()=>handlePublish(_id)} loading={p_loading} /></div>}
        <span role='button' onClick={onClick} >
            <div style={{borderRadius:temp_? `${radius}px`:null}} className="card-container box-shadow">
                <div style={{ background:h_color, borderRadius:radius+"px"}} className='card-header'>
                    {author && <div style={{ whiteSpace: "nowrap",marginRight:"auto",color:h_text_color }}>Posted By: {author}</div>}
                    <div  style={{color:h_text_color}}>{date}</div>
                </div>
                <div  className="card-img">
                    <img src={img} style={{maxWidth:"100%", maxHeight:300 , borderRadius:radius+"px"}} alt={img} />
                </div>
                <div className="card-item">
                    <h4 style={{color:t_color}}>{title}</h4>
                    <p style={{color:d_color}}>{description}</p>
                </div>
            </div>
        </span>
        </div>
    )
}



