import {commonrequest} from "./ApiCall"

export const registerfunc = async(data,header)=>{
    try {
    return await commonrequest("POST",`/user/register`,data,header);
    } catch (err) {
        console.log(err)
        return err
    }
    
}

export const usergetfunc = async(search,options,status,sort,page)=>{
    return await commonrequest("GET",`/user/details?search=${search}&options=${options}&status=${status}&sort=${sort}&page=${page}`,"");
}

export const singleUsergetfunc = async(id)=>{
    return await commonrequest("GET",`/user/${id}`,"");
}

export const editfunc = async(id,data,header)=>{
    return await commonrequest("PUT",`/user/edit/${id}`,data,header);
}

export const deletfunc = async(id)=>{
    return await commonrequest("DELETE",`/user/delete/${id}`,{});
}

export const statuschangefunc = async(id,data)=>{
    return await commonrequest("PUT",`/user/status/${id}`,{data})
}

export const exporttocsvfunc = async()=>{
    return await commonrequest("GET",`/userexport`,"");
}